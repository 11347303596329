<template>
  <div>
    <van-notice-bar color="#2cb5e8" background="#ecf9ff" text="小贴士：浅绿色的代表未处理，灰色的代表已处理，未处理的数据按住向左滑动或者点右上角小圆点可标记已处理" mode="closeable"/>
    <van-collapse v-model="activeName" accordion>
      <van-collapse-item title="筛选条件" class="filter">
           <van-cell title="按设备编号查" :value="filter.devInfo.value" is-link @click="filter.devInfo.showPicker = true"/>
           <van-cell title="按处理结果查" :value="filter.way.value" is-link @click="filter.way.show = true"/>
           <van-cell title="按时间段查询" class="line-cell" :value="filter.time.value" is-link @click="filter.time.show = true"/>
           <van-row class="text-center">
              <van-col span="12"><van-button type="primary" size="small" @click="resetData">重置</van-button></van-col>
              <van-col span="12"> <van-button type="primary" size="small" @click="filterData">筛选</van-button></van-col>
           </van-row>
      </van-collapse-item>
    </van-collapse>
    <div :class="'glo-content ' +(noticeShow ? 'glo-magbot' : 'glo-magbot0')">
        <div v-if="listLoading"><van-loading type="spinner" vertical>加载中</van-loading></div>
        <div v-else>
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getAlarmList" :immediate-check="false">
                <div v-if="infoList.length == 0">
                    <van-empty description="数据列表为空" />
                </div>
                <div v-else>
                    <div v-for="(item,index) in infoList" :key="index" :class=" 'block ' +(item.status == '0'?'not-l':'yet-l')">
                      <van-swipe-cell :disabled="item.status == '0' ? false : true" ref="swipecell">
                        <div>
                          <div class="title">
                              <div v-text="item.content" :class="item.status == '0' ? 'not':'yet-title'"></div>
                              <div><i :class="'fa fa-circle ' +(item.status == '0'?'not':'yet')" @click="markRead(index,item.status)"></i></div>
                          </div>
                          <!-- <p class="inner-txt">告警内容：{{item.content}}</p> -->
                          <p class="inner-txt">告警类型：{{item.typeName || '无'}}</p>
                          <p>告警级别：{{item.level}}</p>
                          <p :class="item.status == '0'?'emphasis':'emphasis-off'">告警值：{{item.value}}</p>
                          <p>正常参考值：{{item.refer}}</p>
                          <p>告警地点：{{item.location || '暂无信息'}}</p>
                          <div class="footer">
                            <div>{{item.devCode}}</div>
                            <div>{{item.createTime}}</div>
                          </div>
                        </div>
                        <template #right>
                          <van-button square class="read-btn" @click="handleAlarm(item.id)" type="info" text="处理" />
                        </template>
                      </van-swipe-cell>
                    </div>
                </div>
            </van-list>
        </div>
    </div>
    <!-- 筛选区域 -->
    <van-popup v-model="filter.devInfo.showPicker" round position="bottom">
        <van-picker show-toolbar :columns="filter.devInfo.columns" @cancel="filter.devInfo.showPicker = false" @confirm="areaConfirm" />
    </van-popup>
    <!-- 筛选处理方式 -->
    <van-action-sheet v-model="filter.way.show" :actions="filter.way.actions" @select="waySelect" />
    <!-- 筛选时间段 -->
    <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#2fd4bd"/>
    <tabbar :parent="alarmtotal" />
  </div>
</template>
<script>
import tabbar from '@/components/wlms/tabbar.vue'
import moment from "moment";
export default {
  components: {
    tabbar
  },
  props: ['magbot'],
  data() {
    return {
      activeName: '',
      infoList:[],
      filter:{
        devInfo:{
          devCode:'',
          value:'',
          showPicker:false,
          columns:[]
        },
        way:{
          code:'',
          value:'',
          show:false,
          actions:[{name:'未处理',id:'0'},{name:'已处理',id:'1'}]
        },
        time:{
          start:'',
          end:'',
          minDate: new Date(2018, 0, 1),
          maxDate: new Date(),
          value:'',
          show:false
        }
      },
      bridgeCode:'',
      pageNum:1,
      pageSize:10,
      loading:false,
      finished:false,
      listLoading:true,
      alarmtotal:'',
      magbotVal:'',
      projectCode:'',
    }
  },
  watch: {
      magbot: function (val) {   
        this.magbotVal = val;   
      }
  },
  methods:{
    // 筛选监测区域确认事件
    areaConfirm(v){
      this.filter.devInfo.showPicker = false;
      this.filter.devInfo.value = v.text;
      this.filter.devInfo.devCode = v.devCode;
    },
    // 筛选处理方式确认事件
    waySelect(v){
      this.filter.way.show = false;
      this.filter.way.value = v.name;
      this.filter.way.code = v.id;
    },
    // 筛选时间确认事件
    timeConfirm(date) {
      const [start, end] = date;
      this.filter.time.show = false;
      this.filter.time.start = moment(start).format("yyyy-MM-DD");
      this.filter.time.end = moment(end).format("yyyy-MM-DD");
      this.filter.time.value = `${this.filter.time.start}至${this.filter.time.end}`;
    },
    // 重置
    resetData(){
      this.filter.devInfo.value = '';
      this.filter.way.value='';
      this.filter.time.value='';
      this.filter.devInfo.devCode = '';
      this.filter.way.code = '';
      this.filter.time.start = '';
      this.filter.time.end = '';
      this.$refs.time.reset();
    },
    // 筛选
    filterData(){
      this.listLoading = true;
      this.finished = false;
      this.activeName = '';
      this.infoList = [];
      this.pageNum = 1;
      this.getAlarmList();
    },
    // 获取设备列表
    devList(){
      this.$api.WLMS.deviceList({projectCode:this.projectCode}).then( d =>{
        this.filter.devInfo.columns = d;
        this.filter.devInfo.columns.forEach( e =>{
           e.text = e.devName;
        })
      })
    },
    // 加载告警信息列表
    getAlarmList(){
      const data = {
        projectCode:this.projectCode,
        devCode:this.filter.devInfo.devCode,
        status:this.filter.way.code,
        beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
        endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` : void 0
      }
      this.$api.WLMS.alarmPage(this.pageNum,this.pageSize,data).then( d =>{
        if(Array.isArray(d)){
            this.listLoading = false;
            this.loading = false;
            this.infoList =this.infoList.concat(d);
            this.pageSize > d.length ? this.finished = true : void 0;
            this.pageNum ++;
        }
      })
    },
    // 告警数据标记已处理
    handleAlarm(id){
       this.$api.WLMS.handleAlarm({id:id}).then( d=>{
          this.$toast('操作成功');
          setTimeout(()=>{
            // this.$api.BRIDGE.getAlarmNum({briCode:this.bridgeCode}).then(d=>{
            //   this.alarmtotal= d.toString();
            //   window.sessionStorage.setItem('onceAlarm',d);
            // })
            this.filterData();
          },1000)
       })
    },
    // 圆点点击标注已读
    markRead(index,status){
      event.stopPropagation();
      status == '0' ? this.$refs.swipecell[index].open('right') : void 0;
    }
  },
  computed:{
    noticeShow(){
      return window.sessionStorage.getItem('magbot') == '1' && this.magbot != 1 ? true : false
    }
  },
  mounted(){
    this.projectCode = window.sessionStorage.getItem("projectCode");
    this.getAlarmList();
    this.devList();
  }
}
</script>
<style lang="scss" scoped>
.filter{text-align: left;}
.block{
    border: 1px solid #ececec;background-color: #fff2db;text-align: left;font-size: 0.8rem;padding: 2%;border-radius: 10px;color: #353535;margin-bottom: 3%;
    box-shadow: 1px 1px #dedede;
    p{margin: 0;padding-bottom: 2%;color: #757575;}
    .inner-txt{text-indent: -5em;margin-left: 5em;padding-top: 2%;}
    .title{
      font-size: .95rem;font-weight: bold;text-align: center;padding: 2%;border-bottom: 1px solid #fff;color: #ff6464;
      .fa{position: absolute;top: 5%;right: 2%;}
      .not{color:#2cb5e8;}
      .yet{color: #aaaaaa;}
      .yet-title{color: #757575;}
    }
    .read-btn{height:100%;border-radius: 5px;margin-left: 28%;width: 72%;}
    .footer{display: flex;justify-content: space-between;border-top: 1px solid #fff;padding: 2% 0;}
    .emphasis{font-weight: bold;color:#2cb5e8;}
    .emphasis-off{font-weight: bold;color: #757575;}
}
.text-center{
  text-align: center;
  button{
    padding: 0 20px;
    margin-top: 5%;
  }
}
.not-l{background-color: #15bfc759;}
.yet-l{background-color: #ececec;}
</style>